import { render, staticRenderFns } from "./LogoContainer.vue?vue&type=template&id=f48badf6&scoped=true&"
import script from "./LogoContainer.vue?vue&type=script&lang=js&"
export * from "./LogoContainer.vue?vue&type=script&lang=js&"
import style0 from "./LogoContainer.vue?vue&type=style&index=0&id=f48badf6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f48badf6",
  null
  
)

export default component.exports