<template>
  <div class="error">
    <img
      alt="Warning"
      src="@/assets/svg/warning.svg"
      height="15"
      width="17">
    <div>
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    validator: {
      type: Object,
      required: true
    },
    messages: {
      type: Object,
      required: true
    }
  },
  computed: {
    error () {
      for (const param in this.validator.$params) {
        if (!this.validator[param]) {
          const message = this.messages[param]
          if (message) {
            return message
          }
          break
        }
      }

      return 'Something is wrong with this field'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.error {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 7px;
  background: $core-white;
  color: $warning;
  padding: 4px 12px;

  img {
    align-self: center;
    margin-right: 11px;
  }
}

</style>
