<template>
  <div
    class="page-banner"
    :class="`page-banner--${background}`">
    <div class="container">
      <div class="row">
        <div class="col-md-7 col-12">
          <h2 class="page-banner__heading">
            {{ heading }}
          </h2>
          <p
            v-if="subheading"
            class="page-banner__subheading teasers-intro">
            {{ subheading }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true
    },
    subheading: {
      type: String,
      required: false,
      default: ''
    },
    background: {
      type: String,
      default: 'main',
      validator: (background) => {
        return [
          'main',
          'alternative'
        ].indexOf(background) !== -1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/typeface.scss";

.page-banner {
  padding: 1rem 0;
  border-top: 2px solid transparent;

  @media(min-width: $breakpoint-md) {
    padding: 2rem 0 2rem;
  }

  &--main {
    background-color: $grey-100;

    @media(min-width: $breakpoint-md) {
      padding: 2rem 0 3rem;
    }
  }

  &--alternative {
    border-top: 2px solid $grey-900;

    @media(min-width: $breakpoint-md) {
      padding: 2rem 0 1.5rem;
    }
  }

  &__heading {
    font-weight: 700;
    @media(min-width: $breakpoint-md) {
      line-height: 50px;
      margin: 28px 0;
    }
  }

  &__subheading {
    @media(min-width: $breakpoint-md) {
      margin: 0.5rem 0;
    }
  }
}
</style>
