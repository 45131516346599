<template>
  <div
    class="logo"
    @click="$emit('click')">
    <div class="logo__container">
      <img
        :src="logoUrl"
        :alt="title"
        class="image">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logoUrl: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>

.logo {
  width: 96px;

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.image {
  max-width: 100%;
}

</style>
