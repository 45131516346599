<template>
  <div class="autocomplete-input">
    <TextInput
      :id="id"
      v-model="model"
      :label="label"
      :read-only="readOnly"
      type="search"
      autocomplete="off"
      :class="{
        'autocomplete-input--expanded': showAutocompletion,
        'autocomplete-input--read-only': readOnly
      }"
      :validator="validator"
      :validation-messages="validationMessages"
      @click.native="handleClick"
      @input="handleInputChange"
      @focus="handleFocus"
      @blur="handleBlur" />
    <transition name="fade">
      <ul
        v-show="showAutocompletion"
        class="autocomplete-input__options"
        :class="{ 'autocomplete-input__options--labeled': label }"
        @mouseout="preselectOption(null)">
        <li
          v-for="(option, index) in options"
          :key="`option-${id}-${index}`"
          class="autocomplete-input__option"
          :class="{
            'autocomplete-input__option--highlight':
              index === preselectedOptionIndex
          }"
          @mouseover="preselectOption(index)">
          {{ option.label }}
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import TextInput from '@/components/form-components/TextInput'
const ARROW_DOWN = 40
const ARROW_UP = 38
const ENTER = 13
const ESCAPE = 27

export default {
  components: {
    TextInput
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    validator: {
      type: Object,
      default: null
    },
    validationMessages: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      fieldFocused: false,
      optionsVisible: false,
      preselectedOptionIndex: null
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    showAutocompletion () {
      const value = this.options.length && this.optionsVisible

      this.$emit('selectionsVisible', value)
      return value
    }
  },
  methods: {
    setFocused (focused) {
      this.fieldFocused = focused
    },
    setOptionsVisible (visible) {
      this.optionsVisible = visible
    },
    preselectOption (option) {
      this.preselectedOptionIndex = option
    },
    preselectNextOption () {
      if (this.preselectedOptionIndex === null) {
        this.preselectedOptionIndex = 0
        return
      }

      if (this.preselectedOptionIndex === this.options.length - 1) {
        return
      }

      this.preselectedOptionIndex += 1
    },
    preselectPreviousOption () {
      if (this.preselectedOptionIndex === null) {
        this.preselectedOptionIndex = this.options.length - 1
        return
      }

      if (this.preselectedOptionIndex === 0) {
        return
      }

      this.preselectedOptionIndex -= 1
    },
    handleSelect () {
      if (this.preselectedOptionIndex !== null) {
        this.$emit('select', this.options[this.preselectedOptionIndex])
        this.preselectOption(null)
        this.setOptionsVisible(false)
      }
    },
    handleDownKey (event) {
      if (!this.optionsVisible) {
        this.setOptionsVisible(true)
      }

      this.preselectNextOption()
      event.preventDefault()
    },
    handleUpKey (event) {
      if (!this.optionsVisible) {
        this.setOptionsVisible(true)
      }

      this.preselectPreviousOption()
      event.preventDefault()
    },
    handleEnterKey (event) {
      if (this.optionsVisible) {
        event.preventDefault()
      }

      if (this.options[this.preselectedOptionIndex]) {
        this.handleSelect()
      }
    },
    handleEscapeKey (event) {
      if (this.optionsVisible) {
        event.preventDefault()
        this.setOptionsVisible(false)
      }
    },
    handleKeyPress (event) {
      switch (event.keyCode) {
        case ARROW_DOWN: {
          this.handleDownKey(event)
          break
        }

        case ARROW_UP: {
          this.handleUpKey(event)
          break
        }

        case ENTER: {
          this.handleEnterKey(event)
          break
        }

        case ESCAPE: {
          this.handleEscapeKey(event)
          break
        }
      }
    },
    enableKeys () {
      window.addEventListener('keydown', this.handleKeyPress)
    },
    disableKeys () {
      window.removeEventListener('keydown', this.handleKeyPress)
    },
    handleFocus (event) {
      this.setFocused(true)
      this.enableKeys()
      this.$emit('focus', event)
    },
    handleBlur (event) {
      this.disableKeys()
      this.setOptionsVisible(false)
      this.setFocused(false)
      this.handleSelect()
      this.$emit('blur', event)
    },
    handleInputChange () {
      this.setOptionsVisible(true)
    },
    handleClick () {
      if (this.fieldFocused) {
        this.setOptionsVisible(!this.optionsVisible)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.autocomplete-input {
  position: relative;

  &--expanded {
    ::v-deep .text-input {
      border-radius: 12px 12px 0 0;
    }
  }

  &--read-only {
    ::v-deep input {
      cursor: pointer;
    }
  }

  &__options {
    position: absolute;
    top: 56px;
    width: 100%;
    z-index: 1;
    padding: 0;
    margin: 0;
    list-style: none;
    background: $core-white;
    box-shadow: 4px 5px 10px -3px rgb(130 130 130 / 97%);

    &--labeled {
      top: 87px;
    }
  }

  &__option {
    cursor: pointer;
    padding: 17px 20px;
    border: 1px solid;
    border-color: $grey-200;

    &--highlight {
      background: $grey-100;
    }

    &:not(:first-child) {
      border-top: none;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>
