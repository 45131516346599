<template>
  <div class="text-input-container">
    <label
      v-if="label"
      class="text-input-label"
      :class="{ 'text-input-label--invalid': isInvalid }"
      :for="id">
      {{ label }}
    </label>
    <input
      :id="id"
      ref="inputField"
      v-model="model"
      :autocomplete="autocomplete"
      class="text-input"
      :class="{ 'text-input--invalid': isInvalid }"
      :type="type"
      :readonly="readOnly"
      :required="isRequired"
      :disabled="disabled"
      :min="min"
      @blur="onBlur"
      @focus="$emit('focus')">
    <ValidationError
      v-if="isInvalid"
      class="validation-error"
      :validator="validator"
      :messages="validationMessages" />
  </div>
</template>

<script>
const types = [
  'text',
  'tel',
  'email',
  'date',
  'search'
]

import ValidationError from '@/components/form-components/ValidationError'
export default {
  components: {
    ValidationError
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text',
      validator: type => types.includes(type)
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    validator: {
      type: Object,
      default: null
    },
    validationMessages: {
      type: Object,
      default: null
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    isInvalid () {
      return this.validator && this.validator.$invalid && this.validator.$dirty
    },
    isRequired () {
      return this.validator
        ? this.validator.$params.required
        : false
    }
  },
  methods: {
    onBlur () {
      this.$emit('blur')
      this.validator && this.validator.$touch()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.text-input-container {
  display: flex;
  flex-direction: column;
}

.text-input {
  height: 56px;
  border: 1px solid $core-black;
  border-radius: 12px;
  padding: 17px 20px;
  background: $core-white;

  &:focus {
    outline: none;
    border: 2px solid $core-black;
  }

  &--invalid {
    border-color: $warning;
    &:focus {
      border-color: $warning;
    }
  }

  &-label {
    margin-bottom: 10px;

    &--invalid {
      color: $warning;
    }
  }
}

.validation-error {
  margin: 5px 10px 0 10px;
}

</style>
