<template>
  <div class="dropdown">
    <AutocompleteInput
      :id="id"
      v-model="dropdownTextProxy"
      :label="label"
      :read-only="true"
      :options="options"
      :validator="validator"
      :validation-messages="validationMessages"
      @select="handleSelect"
      @selectionsVisible="handleSelectionVisible" />
    <img
      class="dropdown__icon"
      :class="{ 'dropdown__icon--rotate': isSelectionVisible, 'dropdown__icon--labeled': label }"
      alt="arrow"
      src="@/assets/svg/arrow_down.svg"
      width="15"
      height="10">
  </div>
</template>

<script>
import AutocompleteInput from '@/components/form-components/AutocompleteInput'
export default {
  components: {
    AutocompleteInput
  },
  props: {
    id: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    validator: {
      type: Object,
      default: null
    },
    validationMessages: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      dropdownText: this.placeholder,
      isSelectionVisible: false
    }
  },
  computed: {
    dropdownTextProxy: {
      get () {
        return this.dropdownText
      },
      set (value) {
        return this.dropdownText = value
      }
    }
  },
  methods: {
    handleSelect (selectedItem) {
      this.$emit('onChange', selectedItem.value)
      this.dropdownTextProxy = selectedItem.label
    },
    handleSelectionVisible (value) {
      this.isSelectionVisible = value
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.dropdown {
  position: relative;

  &__icon {
    pointer-events: none;
    position: absolute;
    right: 25px;
    bottom: 22px;
    transition: fill 0.3s ease, transform 0.3s ease;

    &--rotate {
      transform: rotateX(180deg);
    }
  }
}

</style>
